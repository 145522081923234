import React from "react";

const NumberIncrement = () => {
  return (
      <div className="num-inc">
           <div>

        <div className="num-inc-col">
          <div className="num-inc-col-heading">3+</div>
          <div className="num-inc-col-text">Years <span><br /></span> Experience</div>
        </div>
        
        <div className="num-inc-col">
          <div className="num-inc-col-heading">5,000+</div>
          <div className="num-inc-col-text">Happy <span><br /></span> Client</div>
        </div>

        <div className="num-inc-col">
          <div className="num-inc-col-heading">6,500+</div>
          <div className="num-inc-col-text">Successful <span><br /></span> Project</div>
        </div>



      </div> 
    </div>
  );
};

export default NumberIncrement;
