import React from "react";
import correct from "../media/images/ok.png"

const WhyUsComponent = () => {
  return (
    <div>
      <div className="why-us">
        <div className="why-us-heading">Why <span>"Web BHUMU" </span>?</div>
        <div className="why-us-content">
          <div className="why-us-content-col-1"></div>
          <div className="why-us-content-col-2">

            <div className="why-us-content-col-2-point">
                <div className="why-us-content-col-2-point-icon"><img title="correct icon" src={correct} alt="correct icon"  /></div>
                <div className="why-us-content-col-2-point-content">
                    <h2 className="why-us-content-col-2-point-heading">Innovative Designs</h2>
                    <div className="why-us-content-col-2-point-text">Stand out with creative and unique website designs tailored to your brand.</div>
                </div>
            </div>


            <div className="why-us-content-col-2-point">
                <div className="why-us-content-col-2-point-icon"><img title="correct icon" src={correct} alt="correct icon"  /></div>
                <div className="why-us-content-col-2-point-content">
                    <h2 className="why-us-content-col-2-point-heading">Responsive Layouts</h2>
                    <div className="why-us-content-col-2-point-text">Ensure seamless user experience across all devices with our responsive layouts.</div>
                </div>
            </div>


            <div className="why-us-content-col-2-point">
                <div className="why-us-content-col-2-point-icon"><img title="correct icon" src={correct} alt="correct icon"  /></div>
                <div className="why-us-content-col-2-point-content">
                    <h2 className="why-us-content-col-2-point-heading">SEO Optimization</h2>
                    <div className="why-us-content-col-2-point-text">Boost your online presence with our expert SEO strategies.</div>
                </div>
            </div>


            <div className="why-us-content-col-2-point">
                <div className="why-us-content-col-2-point-icon"><img title="correct icon" src={correct} alt="correct icon"  /></div>
                <div className="why-us-content-col-2-point-content">
                    <h2 className="why-us-content-col-2-point-heading">Fast Turnaround</h2>
                    <div className="why-us-content-col-2-point-text">Get your project completed quickly without compromising on quality.</div>
                </div>
            </div>


            <div className="why-us-content-col-2-point">
                <div className="why-us-content-col-2-point-icon"><img title="correct icon" src={correct} alt="correct icon"  /></div>
                <div className="why-us-content-col-2-point-content">
                    <h2 className="why-us-content-col-2-point-heading">24/7 Support</h2>
                    <div className="why-us-content-col-2-point-text">Enjoy peace of mind with our round-the-clock customer support.</div>
                </div>
            </div>


            <div className="why-us-content-col-2-point">
                <div className="why-us-content-col-2-point-icon"><img title="correct icon" src={correct} alt="correct icon"  /></div>
                <div className="why-us-content-col-2-point-content">
                    <h2 className="why-us-content-col-2-point-heading">Affordable Pricing
</h2>
                    <div className="why-us-content-col-2-point-text">Achieve your web design goals within your budget with our competitive rates.
</div>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUsComponent;
