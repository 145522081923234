import React from "react";

const FooterComponent = () => {
  return (
    <div className="footer">
    <div>
      <div className="footer-content">

        <div className="footer-col-1">
          <div className="footer-col-1-logo">WEB BHUMU</div>
          <div className="footer-col-1-text">
          At "WEB BHUMU", we blend creativity and technology to build stunning, user-friendly websites that help businesses thrive in the digital world. 
          </div>
        </div>

        <div className="footer-col-2">
          <div className="footer-col-2-col">
            <div className="footer-col-2-col-heading">CONTACT</div>
            <div className="footer-col-2-col-points">
              <div>
                <span>
                  <i class="fa-solid fa-location-dot"></i>
                </span>{" "}
                {/* E-12, 1st Floor, Street No.-3, Near Pooja Medical Store,
                Chhalera, Sector-44, Noida -201301 */}
                
                BL 61, Sector 116, Noida, Uttar Pradesh 201301
                
{/* KP2, Q51, Jaypee Greens Wishtown,
Sector 131 Noida 201301 */}
              </div>
              <div>
                <span>
                  <i class="fa-solid fa-phone-volume"></i>
                </span>
                {/* +91 98715 99433 */}
                +91 9658-139-139
              </div>
              <div>
                <span>
                  <i class="fa-solid fa-envelope"></i>
                </span>
                info@bhumu.com
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-cc">
        <i class="fa-regular fa-copyright"></i> Copyright 2024. All Rights Reserved
      </div>

    </div>
    </div>
  );
};

export default FooterComponent;
