import React from 'react'

const WeServedComponent = () => {
  return (
    <div className="we-served">
          <div>
          <div>
        <div className="we-served-heading">We Served <span>Landing Pages</span>  To These <span>Industries</span> </div>
        <div className="we-served-content">
        <div className="we-served-content-points-col">
        <div className="we-served-content-point"><span>❯❯ </span> Matrimonial Website</div>
        <div className="we-served-content-point"><span>❯❯ </span> Schooling & College Website</div>
        <div className="we-served-content-point"><span>❯❯ </span> Tour & Travel Website</div>
        <div className="we-served-content-point"><span>❯❯ </span> Blogging Website</div>
        <div className="we-served-content-point"><span>❯❯ </span> News Website</div>
        <div className="we-served-content-point"><span>❯❯ </span> Social Media Websites</div>
        <div className="we-served-content-point"><span>❯❯ </span> B2B Service Website</div>
        <div className="we-served-content-point"><span>❯❯ </span> Law Firm Website Design</div>
        <div className="we-served-content-point"><span>❯❯ </span> Yoga Website</div>
        <div className="we-served-content-point"><span>❯❯ </span> Hospitality Websites</div>
        <div className="we-served-content-point"><span>❯❯ </span> Real-Estate Website Design</div>
        <div className="we-served-content-point"><span>❯❯ </span> Interior Website Design</div>
        
        </div>
        

        </div>

        </div>
        </div>
    </div>
  )
}

export default WeServedComponent