import React from "react";
import icon1 from "../media/images/icons/Consultation-1.webp"
import icon2 from "../media/images/icons/Planning-3.webp"
import icon3 from "../media/images/icons/Design-3.webp"
import icon4 from "../media/images/icons/Development-3.png"
import icon5 from "../media/images/icons/Testing-2.webp"
import icon6 from "../media/images/icons/Launch-&-Support-2.webp"

const HowWeWork = () => {
  return (
      <div className="how-we">
          <div>
        <div className="how-we-heading">How <span>Web BHUMU </span> Works?</div>
        <div className="how-we-content">

          <div className="how-we-content-col">
            <div className="how-we-content-col-icon"><img title="How we icon" src={icon1} alt="how we icon"  /></div>
            <div className="how-we-content-col-content">
            <div className="how-we-content-col-heading">Consultation</div>
                
            <div className="how-we-content-col-text">We start with a thorough consultation to understand your vision and goals.</div>
            </div>
          </div>
          

          <div className="how-we-content-col">
            <div className="how-we-content-col-icon"><img title="How we icon" src={icon2} alt="how we icon"  /></div>
            <div className="how-we-content-col-content">
            <div className="how-we-content-col-heading">Planning</div>
            <div className="how-we-content-col-text">Our team crafts a detailed plan and timeline tailored to your project's needs.</div>
          </div>
          </div>
          

          <div className="how-we-content-col">
            <div className="how-we-content-col-icon"><img title="How we icon" src={icon3} alt="how we icon"  /></div>
            <div className="how-we-content-col-content">
            <div className="how-we-content-col-heading">Design</div>
            <div className="how-we-content-col-text">Experience our creative process as we design a visually stunning website for you.</div>
          </div>
          </div>
          

          <div className="how-we-content-col">
            <div className="how-we-content-col-icon"><img title="How we icon" src={icon4} alt="how we icon"  /></div>
            <div className="how-we-content-col-content">
            <div className="how-we-content-col-heading">Development</div>
            <div className="how-we-content-col-text">We bring the design to life with robust and scalable development practices.</div>
          </div>
          </div>
          

          <div className="how-we-content-col">
            <div className="how-we-content-col-icon"><img title="How we icon" src={icon5} alt="how we icon"  /></div>
            <div className="how-we-content-col-content">
            <div className="how-we-content-col-heading">Testing</div>
            <div className="how-we-content-col-text">Rigorous testing ensures your site is flawless and performs perfectly on all devices.</div>
          </div>
          </div>
          

          <div className="how-we-content-col">
            <div className="how-we-content-col-icon"><img title="How we icon" src={icon6} alt="how we icon"  /></div>
            <div className="how-we-content-col-content">
            <div className="how-we-content-col-heading">Launch & Support</div>
            <div className="how-we-content-col-text">We launch your site and provide ongoing support to keep it running smoothly.</div>
          </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
