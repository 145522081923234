import React from 'react'
// import logo from "../media/images/BHUMU-white.png"
import { Link } from 'react-router-dom'
import img from "../media/images/bg-1111.png"


const Header = () => {
  return (
      <div className="header">
            <div>
            <div>
        <div className="header-nav">

        <div className="header-nav-logo">
            {/* <img src={logo} alt="" /> */}
            WEB BHUMU 
        </div>

        <div className="header-nav-menu">
            <span><Link title='call link' to="tel:+919871599433" target='_blank'>Enquire Now</Link></span>
        </div>

        </div>

        <div className="header-main">

        <div className="header-main-col">

            <h1 className="header-main-heading-1">
            Get Best Quality <span>Landing Page</span>  <br /> @ Just ₹ <s>4,999</s> <span>₹ 1,999/- </span>In <span>1</span> Hour


            </h1>

            <div className="header-main-points">
                <div className="header-main-points-txt-1"><span>❯❯</span> Free Domain</div>
                <div className="header-main-points-txt-2"><span>❯❯</span> 1 Year Free Hosting </div>
                <div className="header-main-points-txt-3"><span>❯❯</span> Free SSL Certificates</div>
                <div className="header-main-points-txt-4"><span>❯❯</span> Free SEO For 3 Months </div>
                <div className="header-main-points-txt-5"><span>❯❯</span> Free Google Business Profile</div>
                <div className="header-main-points-txt-6"><span>❯❯</span> Social Media Pages</div>
                <div className="header-main-points-txt-7"><span>❯❯</span> Mobile & Desktop Friendly</div>
                <div className="header-main-points-txt-8"><span>❯❯</span> 24/7 mail Support</div>
                <div className="header-main-points-txt-9"><span>❯❯</span> Call Button Integration</div>
                <div className="header-main-points-txt-10"><span>❯❯</span> WhatsApp Button Integration</div>
                <div className="header-main-points-txt-11"><span>❯❯</span> Technical Support For Website</div>
                <div className="header-main-points-txt-12"><span>❯❯</span> Google Location Map</div>
            <div className="header-main-points-txt"></div>
    
            </div>
                <div className="header-main-heading-2"><span>❖</span> No Hidden or Extra Charges</div>

        </div>
        <div className="header-main-col-2">
            <img src={img} alt="header img" title='header img' />
        </div>


        </div>

        <div className="header-button">
            <Link title='call link' target='_blank' to="tel:+91 98715 99433">Call Now</Link>
            <Link title='whatsapp link' target='_blank' to="https://wa.me/+919871599433?text=Hello ! I'm Interested in Website Service. Please contact me.">WhatsApp</Link>
        </div>

        </div>
    </div>
    </div>
  )
}

export default Header