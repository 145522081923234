import React from 'react'
import Header from '../components/Header'
import NumberIncrement from '../components/NumberIncrement'
import WeServedComponent from '../components/WeServedComponent'
import ClientSaysComponent from '../components/ClientSaysComponent'
import ContactButtons from '../components/ContactButtons'
import FooterComponent from '../components/FooterComponent'
import WhyUsComponent from '../components/WhyUsComponent'
import HowWeWork from '../components/HowWeWork'
import KeyWordsComponents from '../components/KeyWordsComponents'

const HomePage = () => {
  return (
    <div>
        <Header/>
        <NumberIncrement/>

        <ClientSaysComponent/>
        
        <WeServedComponent/>
        <WhyUsComponent/>

        <HowWeWork/>

        <KeyWordsComponents/>

<FooterComponent/>


<ContactButtons/>

        
    </div>
  )
}

export default HomePage