import React from 'react'
import './App.css';
import './css/Header.css';
import './css/NumberIncrement.css';
import './css/WeServedComponent.css';
import './css/ClientSaysComponent.css';
import './css/ContactButtons.css';
import './css/Animation.css';
import './css/FooterComponent.css';
import './css/WhyUsComponent.css';
import './css/HowWeWork.css';
import './css/KeyWordsComponents.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
        </Routes>
      </BrowserRouter>
          
    </div>
  )
}

export default App